<template>
	  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signup-on"
      
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #EBF6FFBF;"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <!--<a href="#" class="text-center mb-10">
            <img src="media/klik/logo.png" class="max-h-70px" alt=""/>
          </a>-->
           <div
				  class="d-flex flex-row-fluid bgi-no-repeat bgi-position-y-top bgi-position-x-center" style="background-size: 50%;height: 90px;"
				  :style="{ backgroundImage: `url(${LogoImage})` }"
			></div>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-top bgi-position-x-center" style="background-size: 100%;"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
			<div class="d-flex flex-row-fluid flex-column justify-content-center align-items-center text-center text-black " v-if="HasVerif">
				<h1 class="display-1 font-weight-meduim">
					{{$t('VERIFSMS.MSG_DESC_COMPTE_OK')}}
				</h1>
				<span class="display-4 font-weight-boldest mb-8">
					{{$t('VERIFSMS.MSG_DESC_COMPTE_OK')}}
				</span>
			</div>
			<div class="d-flex flex-row-fluid flex-column justify-content-center align-items-center text-center text-black " v-if="!HasVerif && !HasVerifCodeInput">
				<h1 class="display-1 font-weight-meduim">
					{{$t('VERIFSMS.MSG_TITLE_COMPTE_NOT_OK')}}
				</h1>
				<span class="display-4 font-weight-boldest mb-8">
					{{$t('VERIFSMS.MSG_DESC_COMPTE_NOT_OK')}}
				</span>
			</div>
			
			<div class="d-flex flex-row-fluid flex-column justify-content-center align-items-center text-center text-black " v-if="!HasVerif && HasVerifCodeInput">
				<b class="display-4" >
					<h1>{{$t('VERIFSMS.MSG_VALIDE')}}</h1>
					<br>
					<br>
					{{$t('VERIFSMS.MSG_ENTER_CODE')}}
				</b>
				<br>
				<span class="display-4 font-weight-boldest mb-8">
					<div class="form-group">
						<CodeInput :loading="false" class="input" v-on:complete="onComplete" :values="values"/>
					</div>
				</span>
			</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style  lang="scss">
	@import "@/assets/sass/pages/login/login-1.scss";
	.react-code-input input{
		background: #eee;
		font-size: 50px!important;
	}
</style>
<script>
import axios from "axios";
import CodeInput from "vue-verification-code-input";
export default {
	name: "Error-2",
	data: () => ({
		HasVerif: false,
		HasVerifCodeInput: false,
		values: [],
	}),
	components: {
		CodeInput
	},
	mounted() {
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = process.env.VUE_APP_APP_API;
		if(this.$route.params && this.$route.params.phone != ""){
			if(!isNaN(this.$route.params.phone)){
				for(var i in this.$route.params.phone){
					this.values.push(this.$route.params.phone[i])
				}
				this.VerifNumber(this.$route.params.phone);
			}else{
				this.HasVerifCodeInput = true;
			}
		}
	},//Impossible de valider le numéro de téléphone
	computed: {
		backgroundImage() {
		  return process.env.BASE_URL + "media/klik/b-1.png";
		},
		LogoImage() {
		  return process.env.BASE_URL + "media/klik/logo.png";
		},
	},
	methods: {
		VerifNumber(code) {
			axios.post("/signin/verif/code",{code:code}).then((response) => {
				if(response.data.Verif){
					this.HasVerif = true;
					setTimeout(() => {
						this.$router.push('/login');
					}, 5000);
				}else{
					alert(this.$t('VERIFSMS.MSG_DESC_COMPTE_NOT_OK'));
					this.HasVerif = false;
				}
			}).catch((errors) => {
				console.log(errors);
			});
		},
		onComplete(v) {
			this.VerifNumber(v);
		}
	},
}; 
</script>
